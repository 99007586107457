import {createRouter, createWebHistory} from 'vue-router';
import {useMainStore} from "@/store/pinia";
import UserManagement from "@/views/user/UserManagement.vue";
import Home from "@/views/Home.vue";
import ArticleManagement from "@/views/contents/ArticleManagement.vue";
import ExposureManagement from "@/views/contents/ExposureManagement.vue";
import BannerManagement from "@/views/contents/BannerManagement.vue";
import BannerDetail from "@/views/contents/BannerDetail.vue";
import ArticleDetail from "@/views/contents/ArticleDetail.vue";
import UserDetail from "@/views/user/UserDetail.vue";
import MenuManagement from "@/views/system/MenuManagement.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/content/banner',
        name: 'Banner',
        component: BannerManagement,
    },
    {
        path: '/content/banner/:id',
        name: 'BannerDetail',
        component: BannerDetail,
        props: true
    },
    {
        path: '/content/exposure',
        name: 'Exposure',
        component: ExposureManagement,
        props: true
    },
    {
        path: '/article/:articleType',
        name: 'Article',
        component: ArticleManagement,
        props: true
    },
    {
        path: '/content/article/:articleType/detail/:id?',
        name: 'ArticleDetail',
        component: ArticleDetail,
        props: true
    },
    {
        path: '/user/:userType',
        name: 'User',
        component: UserManagement,
        props: true
    },
    {
        path: '/user/:userType/:mode/:id?',
        name: 'UserDetail',
        component: UserDetail,
        props: true
    },
    {
        path: '/menu/:type',
        name: 'MenuManagement',
        component: MenuManagement,
        props: true
    },
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0)
    const mainStore = useMainStore();
    localStorage.setItem('currentPath', mainStore.currentPath);
    next()
});

export default router;
