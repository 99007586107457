<template>
  <div class="contents">
    <div class="layout">
      <div class="page-top">
        <h2>아티스트 노출 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">아티스트 노출 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input" placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <colgroup>
                <col style="width:10%">
                <col style="width:30%">
                <col style="width:40%">
                <col style="width:20%">
              </colgroup>
              <thead>
              <tr>
                <th>NO</th>
                <th>이름</th>
                <th>노출여부</th>
                <th>수정일</th>
              </tr>
              </thead>
              <tbody ref="tbodyRef">
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ index+1 }}</td>
                <td>{{ item.artistNameKo }}</td>
                <td>
                  <select v-model="item.isExposure" class="sel1" @change="handleChange(item)">
                    <option :value="true">노출</option>
                    <option :value="false">비노출</option>
                  </select>
                </td>
                <td>{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";

const items = ref([]);
const searchQuery = ref('');
const API_URL = "/content";

const fetchItems = async () => {
  await apiClient.post(API_URL + '/exposure/artist', {
    name: searchQuery.value,
    isPublished: null,
  })
      .then(response => {
        items.value = response.data;
        console.log(response.data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
};

const handleChange = async (item) => {
  if(confirm("노출 상태를 변경 하시겠습니까?")){
    try {
      await apiClient.post(API_URL + '/exposure/update', {
        id: item.artistId,
        isPublished: item.isExposure
      });
      await fetchItems();
      alert("변경 되었습니다.")
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }else{
    await fetchItems();
  }
};

const search = () => {
  fetchItems();
};


onMounted(async () => {
  await fetchItems();
});

</script>
