<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>사용자 관리</h2>
        <div class="breadcrumbs">
          <span>사용자 관리</span>
          <span class="color-black">일반 회원 관리</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div v-if="mode === 'update'" class="white-panel mt30">
        <div v-if="item" class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>이름<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.user.name"/>
              </td>
            </tr>
            <tr>
              <th>아이디<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.user.username"/>
              </td>
            </tr>
            <tr v-if="userType === 'artist'">
              <th>작가명<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.artistNameKo"/>
              </td>
            </tr>
            <tr v-if="userType === 'artist'">
              <th>작가영문명<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.artistNameEn"/>
              </td>
            </tr>
            <tr>
              <th>이메일<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.user.email"/>
              </td>
            </tr>
            <tr>
              <th>권한<em class="required">*</em></th>
              <td colspan="5">
                <input type="text" v-model="item.roles[0].name" style="background-color: #f3f3f3;" disabled/>
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>생년월일</th>
              <td colspan="5">
                <input type="text" v-model="item.user.birthDate"/>
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>연락처</th>
              <td colspan="5">
                <input type="text" v-model="item.user.phone"/>
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>성별</th>
              <td colspan="5">
                <input type="text" v-model="item.user.gender"/>
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>주소</th>
              <td colspan="5">
                <input type="text" v-model="item.user.address"/>
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>상세주소</th>
              <td colspan="5">
                <input type="text" v-model="item.user.addressDetail"/>
              </td>
            </tr>
            <tr v-if="userType === 'artist'">
              <th>관심분야</th>
              <td v-for="interest in interestsOptions1" :key="interest.id">
                  <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value" v-model="item.user.interests"
                         @change="changeInterests">
                  <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                  <input v-if="interest.value === 'ETC' && item.user.interests.includes('ETC')"
                         v-model="item.user.interestEtc"
                         type="text" class="etc" placeholder="직접 입력">
              </td>
            </tr>
            <tr v-if="userType !== 'artist' && userType !== 'admin'">
              <th>관심분야</th>
              <td v-for="interest in interestsOptions2" :key="interest.id">
                <input type="checkbox" :id="'chk-ct' + interest.id" :value="interest.value" v-model="item.user.interests"
                       @change="changeInterests">
                <label :for="'chk-ct' + interest.id">{{ interest.label }}</label>
                <input v-if="interest.value === 'ETC' && item.user.interests.includes('ETC')"
                       v-model="item.user.interestEtc">
              </td>
            </tr>
            <tr v-if="userType !== 'admin'">
              <th>정책동의<em class="required">*</em></th>
              <td>
                <input type="checkbox" v-model="item.user.terms" id="terms"/>
                <label for="terms">이용약관</label>
              </td>
              <td>
                <input type="checkbox" v-model="item.user.privacyRequired" id="privacyRequired"/>
                <label for="privacyRequired">개인정보 처리 방침</label>
              </td>
              <td>
                <input type="checkbox" v-model="item.user.privacyOptional" id="privacyOptional"/>
                <label for="privacyOptional">개인정보 수집 및 이용</label>
              </td>
            </tr>
            <tr v-if="userType === 'artist' || userType === 'approval'">
              <th style="vertical-align: top;">아티스트 활동 자료</th>
              <td v-for="file in item.files">
                <a :href="file.fileUrl" download="image.jpg" target="_blank">{{ file.fileType }}
                  <img :src="file.fileUrl" alt="Downloadable Image" style="width: 100px;height: 100px;"/>
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-else class="white-panel mt30">
        <div v-if="mode === 'insert'" class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>아이디</th>
              <td>
                <input type="text" v-model="admin.username" placeholder="8~20자의 영문, 숫자를 사용해 주세요."/>
                <p v-if="usernameError" class="txt" style="color: red;">{{ usernameError }}</p>
              </td>
            </tr>
            <tr>
              <th>비밀번호</th>
              <td>
                <input @input="validateForm" type="password" v-model="password1" placeholder="8~16자의 영문 대/소문자+숫자+특수문자"/>
                <p v-if="passwordError1" class="txt" style="color: red;">{{ passwordError1 }}</p>
              </td>
            </tr>
            <tr>
              <th>비밀번호 확인</th>
              <td>
                <input @input="validateForm" type="password" v-model="password2" placeholder="8~16자의 영문 대/소문자+숫자+특수문자"/>
                <p v-if="passwordError2" class="txt" style="color: red;">{{ passwordError2 }}</p>
              </td>
            </tr>
            <tr>
              <th>이름</th>
              <td>
                <input type="text" v-model="admin.name"/>
              </td>
            </tr>
            <tr>
              <th>이메일</th>
              <td>
                <input type="text" v-model="admin.email"/>
              </td>
            </tr>
            <tr>
              <th>권한</th>
              <td>
                <select v-model="admin.role">
                  <option value="" disabled>선택</option>
                  <option v-for="item in rolesOptions" :value="item.value">{{ item.label }}</option>
                </select>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'User', params: { userType: userType }}" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button v-if="mode === 'update'" @click="updateItem" type="button" class="button navy-button">수정하기
          </button>
          <button v-else @click="saveItem" type="button" class="button navy-button">등록하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
const mode = ref(route.params.mode);
const userType = ref(route.params.userType);
const userId = ref(route.params.id);

const item = ref(null);
const admin = ref({
  username: "",
  password: "",
  email: "",
  role: "",
});

const password1 = ref('');
const password2 = ref('');
const passwordError1 = ref('');
const passwordError2 = ref('');

const interestsOptions1 = [
  {id: 1, value: 'PROMOTION', label: '아티스트 및 작품 홍보'},
  {id: 2, value: 'ARCHIVING', label: '아카이빙 및 포트폴리오 기능 이용'},
  {id: 3, value: 'SELLING_ART', label: '직접 제작한 아트상품 판매'},
  {id: 4, value: 'INFORMATION', label: '예술 관련 정보 확인'},
  {id: 5, value: 'COMMUNITY', label: '커뮤니티 활동'},
  {id: 6, value: 'COMMERCIAL_OPPORTUNITIES', label: '상업적 기회(협업 등)'},
  {id: 7, value: 'ETC', label: '기타(직접 입력)'},
];
const interestsOptions2 = [
  {id: 1, value: 'PROMOTION', label: '아티스트 및 작품 정보'},
  {id: 2, value: 'COMMUNITY', label: '커뮤니티 활동'},
  {id: 3, value: 'INFORMATION', label: '예술 관련 정보'},
  {id: 4, value: 'BUYING_ART', label: '아티스트 상품 구매'},
  {id: 5, value: 'ETC', label: '기타(직접 입력)'},
];
const rolesOptions = [
  {id: 1, value: 'ROLE_ADMIN', label: '슈퍼 관리자'},
  {id: 2, value: 'ROLE_MENU_MANAGER', label: '메뉴 관리자'},
  {id: 3, value: 'ROLE_USER_MANAGER', label: '사용자 관리자'},
];

const fetchItem = async () => {
  if (userId.value) {
    await apiClient.post(`/user/${userType.value}/detail`, {
      id: userId.value,
    })
        .then(response => {
          console.log(response.data)
          delete response.data['createdAt'];
          delete response.data['updatedAt'];
          item.value = response.data;
          if(userType.value !== 'artist')
            item.value.user = response.data;
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
};

const changeInterests = (event) => {
  if(event.target.checked){
    item.value.user.interests.push(event.target.value)
  }else{
    item.value.user.interests = item.value.user.interests.filter(item => item !== event.target.value);
  }
};

const updateItem = async () => {
  const confirmMsg = mode.value === 'insert' ? "등록 하시겠습니까?" : "수정 하시겠습니까?";
  const alertMsg = mode.value === 'insert' ? "등록 되었습니다." : "수정 되었습니다.";
  if (confirm(confirmMsg)) {
    try {
      console.log(item.value)
      await apiClient.put(`/user/admin/save`, item.value);
      alert(alertMsg)
      await fetchItem();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
};
const saveItem = async () => {
  if (!await validateUsername()) {
    return;
  }
  if (!validatePassword()) {
    return;
  }
  if (!validateForm()) {
    return;
  }
  const confirmMsg = mode.value === 'insert' ? "등록 하시겠습니까?" : "수정 하시겠습니까?";
  const alertMsg = mode.value === 'insert' ? "등록 되었습니다." : "수정 되었습니다.";
  admin.value.password = password1.value;
  if (confirm(confirmMsg)) {
    try {
      await apiClient.put(`/user/admin/save`, admin.value);
      alert(alertMsg)
      await fetchItem();
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  }
};

const validateForm = () => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!admin.value.email) {
    alert('이메일을 입력해 주세요.');
    return false;
  } else if (!emailRegex.test(admin.value.email)) {
    alert('유효한 이메일 주소를 입력해 주세요.');
    return false;
  } else {
    return true;
  }
};

const usernameError = ref('');
const validateUsername = async () => {
  const usernameRegex = /^(?=.*[a-z])[a-z0-9]{5,20}$/;
  if (!admin.value.username) {
    usernameError.value = '아이디를 입력해 주세요.';
    return false;
  } else if (!usernameRegex.test(admin.value.username)) {
    usernameError.value = '5~20자의 영문 소문자, 숫자만 사용 가능합니다.';
    return false;
  } else {
    try {
      const response = await apiClient.post('/user/admin/checkUser', {username: admin.value.username});
      if (response.data) {
        usernameError.value = '이미 사용 중인 아이디입니다.';
        return false;
      } else {
        usernameError.value = '';
      }
    } catch {
      usernameError.value = '아이디 중복 확인 중 오류가 발생했습니다.';
      return false;
    }
  }
  return true;
};

const validatePassword = () => {
  const passwordRegex = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+[\]{};':"\\|,.<>/?`~]).{8,16}$/;
  if (!password1.value) {
    passwordError1.value = '비밀번호를 입력해 주세요.';
    return false;
  } else if (!passwordRegex.test(password1.value)) {
    passwordError1.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    return false;
  } else {
    passwordError1.value = '';
  }

  if (!password2.value) {
    passwordError2.value = '비밀번호를 입력해 주세요.';
    return false;
  } else if (!passwordRegex.test(password2.value)) {
    passwordError2.value = '8~16자의 영문 대/소문자+숫자+특수문자를 사용해 주세요.';
    return false;
  } else if (password1.value !== password2.value) {
    passwordError2.value = '비밀번호가 일치하지 않습니다.';
    return false;
  } else {
    passwordError2.value = '';
  }
  return true;
}

onMounted(() => {
  if (mode.value === "update") {
    fetchItem();
  }
});
</script>

<style scoped>
input[type="text"]{
  background-color: #e5edff;
}
</style>