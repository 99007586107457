<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>ARTICLE 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">ARTICLE 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input" placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="deleteItems" type="button" class="delete-button">삭제</button>
            <button @click="addArticle" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <colgroup>
                <col style="width:60px;">
                <col style="width:70px;">
                <col>
                <col style="width:120px;">
                <col style="width:120px;">
                <col style="width:120px;">
              </colgroup>
              <thead>
              <tr>
                <th>
									<span class="checkbox-type1">
										<input @click="toggleAllCheck" type="checkbox" id="allchk">
										<label for="allchk">체크</label>
									</span>
                </th>
                <th>NO</th>
                <th>제목</th>
                <th>작성자</th>
                <th>등록일</th>
              </tr>
              </thead>
              <tbody ref="tbodyRef">
              <tr v-for="(item, index) in items" :key="index">
                <td>
                    <span class="checkbox-type1">
                        <input v-model="selectedItems" :id="item.id" type="checkbox" :value="item.id">
                        <label :for="item.id">체크</label>
                    </span>
                </td>
                <td>{{ index+1 }}</td>
                <td>
                  <router-link :to="{ name: 'ArticleDetail', params: { id: item.id, articleType: item.articleType } }">{{ item.title }}</router-link>
                </td>
                <td>{{ item.createdBy }}</td>
                <td>{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import router from "@/router";

const route = useRoute();
const items = ref([]);
const selectedItems = ref([]);
const searchQuery = ref('');
const articleType = ref(route.params.articleType);
const API_URL = "/content";

const fetchItems = async () => {
  await apiClient.post(API_URL + '/article/list', {
    name: searchQuery.value,
    attributes : {articleType : articleType.value}
  })
      .then(response => {
        console.log(response.data)
        items.value = response.data;
      })
      .catch(error => {
        console.error('Error:', error);
      });
};

const deleteItems = async () => {
  if(selectedItems.value.length === 0){
    alert("삭제할 항목을 선택하세요.");
    return;
  }
  if(confirm("삭제 하시겠습니까?")){
    try {
      const response = await apiClient.post(API_URL + '/article/delete', { items: selectedItems.value });
      if(response.status === 200)
        alert("삭제되었습니다.")
      await fetchItems();
    } catch (error) {
      console.error('Error deleting items:', error);
    }
  }
};

const search = () => {
  fetchItems();
};

const addArticle = () => {
  router.push("/content/article/"+articleType.value+"/detail")
};

onMounted(async () => {
  await fetchItems();
});

</script>
