<template>
  <aside v-if="menuList" class="active">
    <button type="button" class="toggle-aside"></button>
    <a href="#" class="link-dashboard">메인 대시보드</a>
    <dl class="toggle-box2">
      <template v-for="item in menuList" :key="item.id">
        <div class="menu-item">
          <input type="checkbox" :id="'menu-item-'+ item.id" class="menu-toggle" style="display: none;"/>
          <label :for="'menu-item-'+item.id" class="menu-label">
            <dt>
              {{ item.name }}
            </dt>
          </label>
          <dd class="menu-content">
            <template v-if="item.children && item.children.length">
              <template v-for="menuChildren in item.children.sort((a, b) => a.sort - b.sort)" :key="menuChildren.id">
                <router-link :to="menuChildren.url">
                  {{ menuChildren.name }}
                </router-link>
              </template>
            </template>
          </dd>
        </div>
      </template>
    </dl>
  </aside>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {apiClient} from "@/services/auth-header"; // Pinia 스토어 경로
const menuVisible = ref(false);
const menuList = ref([
  {children:[{}]}
]);

const toggleMenu = () => {
  menuVisible.value = !menuVisible.value
}

const fetchMenuData = async () => {
  await apiClient.get('/home')
      .then(response => {
        menuList.value = response.data.menu;
        console.log("fetchMenuData", response.data.menu)
      })
      .catch(error => {
        console.error('Error:', error);
      });
};

onMounted(() => {
  fetchMenuData();
});
</script>
<style>
.menu-content {
  display: none;
}

.menu-toggle:checked + .menu-label + .menu-content {
  display: block;
}

.menu-label {
  cursor: pointer;
}
</style>