<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>{{ item.description }}</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">배너 관리</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>분류<em class="required">*</em></th>
              <td>
                <select id="articleType" v-model="item.articleType" disabled>
                  <option value="special">special</option>
                  <option value="exhibition">exhibition</option>
                  <option value="event">event</option>
                  <option value="news">news</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>제목<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.title"/>
              </td>
            </tr>
            <tr>
              <th>분류<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.subTitle"/>
              </td>
            </tr>
            <tr>
              <th>카테고리<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.category"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>기간<em class="required">*</em></th>
              <td>
                <input type="text" id="period" v-model="item.period"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>시간<em class="required">*</em></th>
              <td>
                <input type="text" id="time" v-model="item.time"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>장소<em class="required">*</em></th>
              <td>
                <input type="text" id="location" v-model="item.location"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>내용<em class="required">*</em></th>
              <td>
                <textarea v-model="item.content"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>URL<em class="required">*</em></th>
              <td>
                <input type="text" v-model="item.link"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>유투브<em class="required">*</em></th>
              <td>
                <input type="text" id="articleYoutubeUrl" v-model="item.articleYoutubeUrl"/>
              </td>
            </tr>
            <tr v-if="articleType !== 'special'">
              <th>연락처<em class="required">*</em></th>
              <td>
                <input type="text" id="phoneNumber" v-model="item.phoneNumber"/>
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">메인 이미지</th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="ex_file">
                            <span>{{ item.imageUrl }}</span>
                          </label>
                          <input type="file" id="ex_file" @change="handleFileUpload" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <img v-if="item.articleImageUrl" :src="item.articleImageUrl" style="width: 200px; height: 200px;" alt=""/>
                </div>
              </td>
            </tr>
            <tr v-if="articleType === 'special'">
              <th style="vertical-align: top;">상세 이미지</th>
              <td>
                <div class="file-list">
                  <ul>
                    <li v-for="(article, index) in articleDetails">
                      <input type="text" v-model="article.title" placeholder="제목" style="height: 50px"/>
                      <textarea v-model="article.content" placeholder="내용"/>
                      <div class="attachment">
                        <div class="filebox2">
                          <label :for="'upload-' + index">
                            <span>{{ item.imageUrl }}</span>
                          </label>
                          <input type="file" :id="'upload-' + index" @change="handleSpecialFileUpload($event, index)" accept="image/*"/>
                        </div>
                      </div>
                      <img v-if="article.imageUrl" :src="article.imageUrl" style="width: 200px; height: 200px;" alt=""/>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'Article', params: { articleType: item.articleType }}" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button v-if="articleId" @click="saveItem" type="button" class="button navy-button">수정하기</button>
          <button v-else @click="saveItem" type="button" class="button navy-button">등록하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useMainStore} from "@/store/pinia";
import {useRoute} from "vue-router";

const route = useRoute();
const mainStore = useMainStore();
const articleId = ref(route.params.id);
const articleType = ref(route.params.articleType);
const item = ref({
  articleType: articleType,
  title: '',
  content: '',
  period: '',
  time: '',
  location: '',
  phoneNumber: '',
  link: '',
  articleImageUrl: '',
  articleYoutubeUrl: '',
  articleDetails: [],
  isUse: true
})
const articleDetails = ref(
    Array.from({ length: 5 }, (_, index) => ({
      order: index,
      id: null,
      title: '',
      content: '',
      file: null,
      imageUrl: '',
      isChange: false,
    }))
);
const selectedFile = ref(null);
const selectedFiles = ref([]);
const API_URL = "/content";
let imageUrl = ''

const fetchItem = async () => {
  try {
    const response = await apiClient.post(API_URL + '/article/detail', {
      id: articleId.value,
      attributes : {articleType : articleType.value}
    });
    delete response.data['createdAt'];
    delete response.data['updatedAt'];
    const responseDetail = response.data.articleDetails;
    responseDetail.forEach(item => {
      delete item.createdAt;
      delete item.updatedAt;
    })
    item.value = response.data;
    articleDetails.value = Array.from({ length: 5 }, (_, index) => responseDetail[index] || {
      id: null,
      articleId: response.data.id,
      order: index,
      title: '',
      content: '',
      file: null,
      imageUrl: '',
      isChange: false,
    })
  } catch (error) {
    console.error(error)
  }
};

const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0];
  imageUrl = URL.createObjectURL(event.target.files[0]);
  item.value.articleImageUrl = imageUrl;
};
const handleSpecialFileUpload = (event, index) => {
  const file = event.target.files[0];
  selectedFiles.value[index] = file;
  if (file) {
    articleDetails.value[index].imageUrl = URL.createObjectURL(file);
    articleDetails.value[index].isChange = true;
  }
};

const saveItem = async () => {
  const msg = articleId.value ? "수정 하시겠습니까?" : "등록 하시겠습니까?";
  const resultMsg = articleId.value ? "수정 되었습니다." : "등록 되었습니다.";
  const formData = new FormData();
  if(articleType.value === "special"){
    selectedFiles.value.forEach(file => {
      formData.append(`images`, file);
    });
  }
  if (selectedFile.value) {
    formData.append('articleImage', selectedFile.value);
  }
  item.value.articleDetails = articleDetails.value;
  formData.append('attributes', JSON.stringify(item.value));
  if(confirm(msg)){
    try {
      await apiClient.post(API_URL + '/article/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(resultMsg);
      if(articleId.value){
        await fetchItem();
      }else{
        history.back();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error.response.data);
    }
  }
};

onMounted(() => {
  console.log(articleId.value)
  if(articleId.value){
    fetchItem();
  }
});
</script>