<template>
  <div id="wrap">
    <HeaderSection :show-overlay="showOverlay" @mode-change="handleModeChange"/>
    <div @toggle="togglePanel" class="panel on">
      <MenuSnb/>
      <router-view :key="$route.fullPath" @mode-change="handleModeChange"/>
    </div>
  </div>
</template>
<script setup>
import {onMounted, ref} from 'vue';
import HeaderSection from '@/components/layout/Header.vue';
import MenuSnb from "@/components/snb/MenuSnb.vue";

const showOverlay = ref(false);
const mode = ref('none');

const handleModeChange = (newMode) => {
  mode.value = newMode;
  showOverlay.value = (newMode !== 'none');
};

const redirectIfWww = () => {
  const host = window.location.hostname;
  const url = window.location.href;

  if (host.startsWith('www.')) {
    const newUrl = url.replace('://www.', '://');
    window.location.replace(newUrl);
  }
};

onMounted(() => {
  document.body.classList.remove('on');
  document.querySelector('.panel').classList.add('on');
  redirectIfWww();
});
const togglePanel = () => {
  document.querySelector('.panel').classList.toggle('on');
};
</script>

