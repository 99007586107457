// src/store/pinia.js
import {defineStore} from 'pinia';
export const useMainStore = defineStore('main', {
    state: () => ({
        currentPath: ''
    }),
    actions: {
        setCurrentPath(path) {
            this.currentPath = path;
        }
    }
});
