<template>
  <div class="contents">
    <div class="layout">
      <!-- s::title-breadcrumbs -->
      <div class="page-top">
        <h2>배너 관리</h2>
        <div class="breadcrumbs">
          <span>컨텐츠 관리</span>
          <span class="color-black">배너 관리</span>
        </div>
      </div>
      <!-- e::title-breadcrumbs -->

      <div class="white-panel mt30">
        <div class="table-wrap">
          <table class="table-type2">
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>분류<em class="required">*</em></th>
              <td>
                <select v-model="item.type">
                  <option value="MAIN">MAIN</option>
                  <option value="ARTARTIST">ARTARTIST</option>
                  <option value="ARTICLER">ARTICLER</option>
                  <option value="ARTICLE">ARTICLE</option>
                  <option value="SHOP">SHOP</option>
                </select>
              </td>
            </tr>
            <tr>
              <th>순서<em class="required">*</em></th>
              <td>
                <input type="number" v-model="item.sort" max="100" maxlength="10 "style="max-width:540px;"/>
              </td>
            </tr>
            <tr>
              <th>설명<em class="required">*</em></th>
              <td>
                <input v-model="item.description" type="text" placeholder="설명을 입력하세요." style="max-width:540px;">
              </td>
            </tr>
            <tr>
              <th style="vertical-align: top;">첨부파일</th>
              <td>
                <div class="file-list">
                  <ul>
                    <li>
                      <div class="attachment">
                        <div class="filebox2">
                          <label for="ex_file">
                            <span>{{ item.imageUrl }}</span>
                          </label>
                          <input type="file" id="ex_file" @change="handleFileUpload" accept="image/*"/>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div class="attachment">
                        <div v-if="item.imageUrl" class="filebox2">
                          <img v-if="item.imageUrl.indexOf('mp4') < 0" :src="item.imageUrl" style="width: 200px;height: 200px;" alt=""/>
                          <video controls v-if="item.imageUrl.indexOf('mp4') > 0">
                            <source :src="item.imageUrl" type="video/mp4">
                          </video>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="button-panel mt30">
        <div class="left">
          <router-link :to="{ name: 'Banner' }" class="button white-button round-button gray2">목록</router-link>
        </div>
        <div class="right">
          <button v-if="typeof bannerId === 'number'" @click="saveItem" type="button" class="button navy-button">수정하기</button>
          <button v-else @click="saveItem" type="button" class="button navy-button">등록하기</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";

const route = useRoute();
const bannerId = ref(Number(route.params.id));
const item = ref({
  id: null,
  type: '',
  description: '',
  sort: 0,
  imageUrl: '',
  isUse: true
})
const selectedFile = ref(null);
const API_URL = "/content";
const fetchItem = async () => {
  try {
    const response = await apiClient.post(API_URL + '/banner/detail', {
      id: bannerId.value,
    });
    console.log(response.data)
    delete response.data['createdAt'];
    delete response.data['updatedAt'];
    item.value = response.data;
  } catch (error) {
    console.error(error)
  }
};

const handleFileUpload = (event) => {
  selectedFile.value = event.target.files[0];
  item.value.imageUrl = URL.createObjectURL(event.target.files[0]);
};

const saveItem = async () => {
  if(item.value.sort < -1 || item.value.sort === "" ){
    alert("순서를 입력해 주세요.");
    return;
  }
  if(item.value.type === ""){
    alert("분류를 선택해 주세요.");
    return;
  }
  if(item.value.description === ""){
    alert("설명을 입력해 주세요.");
    return;
  }
  const msg = typeof bannerId.value === 'number' ? "수정 하시겠습니까?" : "등록 하시겠습니까?";
  const resultMsg = typeof bannerId.value === 'number' ? "수정 되었습니다." : "등록 되었습니다.";
  const formData = new FormData();
  if (selectedFile.value) {
    formData.append('images', selectedFile.value);
  }
  formData.append('data', JSON.stringify(item.value));
  if(confirm(msg)){
    try {
      await apiClient.post(API_URL + '/banner/save', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      alert(resultMsg);

      if(typeof bannerId.value === 'number'){
        window.location.reload();
      }else{
        window.history.back();
      }
    } catch (error) {
      console.error('Error uploading file:', error);
      alert(error.response.data);
    }
  }
};

onMounted(() => {
  console.log("bannerId.value",bannerId.value)
  if(bannerId.value){
    fetchItem();
  }
});
</script>