<template>
  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>일반 회원 관리</h2>
        <div class="breadcrumbs">
          <span>사용자 관리</span>
          <span class="color-black">일반 회원 관리</span>
        </div>
      </div>

      <div class="white-panel mt30 pt30 pb30">
        <div class="search-panel">
          <table>
            <colgroup>
              <col style="width:150px;">
              <col>
            </colgroup>
            <tbody>
            <tr>
              <th>검색어</th>
              <td>
                <div class="input-search">
                  <input v-model="searchQuery" @keyup.enter="fetchItems" type="text" name="search-input"
                         placeholder="검색어 입력">
                  <i @click="fetchItems" class="button-search" style="cursor: pointer;"><img
                      src="@/assets/images/ico/ico_search.svg" alt=""/></i>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div v-if="userType === 'admin'" class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="addAdmin" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="white-panel mt20">
        <div class="table-wrap">
          <div class="scroll-box">
            <table class="table-type1">
              <thead>
              <tr>
                <th>NO</th>
                <th>이름</th>
                <th v-if="userType === 'admin'">이메일</th>
                <th v-if="userType !== 'admin'">등록일</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in items" :key="index">
                <td>{{ index + 1 }}</td>
                <td v-if="userType !== 'artist'">
                  <router-link :to="{ name: 'UserDetail', params: {userType: userType, id: item.id, mode: 'update' } }"
                               class="subject">{{ item.name }}
                  </router-link>
                </td>
                <td v-else>
                  <router-link
                      :to="{ name: 'UserDetail', params: {userType: userType, id: item.artistId, mode: 'update' } }"
                      class="subject">{{ item.artistNameKo }}
                  </router-link>
                </td>
                <td v-if="userType === 'admin'">{{ item.email }}</td>
                <td v-if="userType === 'approval'">
                  <button @click="approval(item.id, item.username)" type="button" class="button red-button small">승인
                  </button>
                </td>
                <td v-if="userType !== 'admin'">{{ item.createdAt }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import {useRoute} from "vue-router";
import router from "@/router";

const route = useRoute();
const userType = ref(route.params.userType);
const items = ref([]);
const searchQuery = ref('');

const fetchItems = async () => {
  await apiClient.post(`/user/${userType.value}/list`, {
    name: searchQuery.value
  })
      .then(response => {
        items.value = response.data ?? []; // 데이터를 확인하여 null일 경우 빈 배열 할당
        console.log(response.data)
      })
      .catch(error => {
        console.error('Error:', error);
      });
};

const approval = async (id, username) => {
  if (confirm("승인 하시겠습니까?")) {
    await apiClient.put(`/user/${userType.value}/confirm`, {
      id: id,
      username: username
    })
        .then(response => {
          alert("승인 되었습니다.")
        })
        .catch(error => {
          console.error('Error:', error);
        });
    await fetchItems();
  }
};

const addAdmin = () => {
  router.push("/user/admin/insert")
};

const search = () => {
  fetchItems();
};

onMounted(async () => {
  await fetchItems();
});
</script>