<template>
  <header>
    <div class="h-left">
      <a href="#">
        <img src="@/assets/images/common/logo_b.svg" alt=""/>
      </a>
    </div>
    <div class="h-right">
      <a href="https://article21.kr" target="_blank" class="site-link">사이트 바로가기</a>
      <div class="h-util">
        <router-link v-if="!currentUser" :to="{ name: 'Home'}" class="color-navy">로그인</router-link>
        <router-link v-else to="#" @click="logOut" class="color-navy">로그아웃</router-link>
      </div>
    </div>
  </header>
</template>

<script setup>
import {computed, nextTick, onMounted, ref} from 'vue';
import {useRouter} from 'vue-router';
import {useAuthStore} from '@/store/auth.module';

const currentUser = computed(() => authStore.status.user);
const authStore = useAuthStore();
const router = useRouter();
const isAdmin = ref(false);

const logOut = () => {
  authStore.logout();
  router.push('/').then(() => {
    window.location.reload();
  });
};

// 컴포넌트 마운트 시 동작 설정
onMounted(() => {
  const isAuthenticated = computed(() => authStore.status.loggedIn);
  if (isAuthenticated.value) {
    const user = authStore.status.user;
    if (user && user.roles && user.roles.length > 0) {
      isAdmin.value = user.roles.includes('ROLE_ADMIN');
    }
  }
});
</script>