<template>

  <div class="contents">
    <div class="layout">

      <div class="page-top">
        <h2>사용자 메뉴 관리</h2>
        <div class="breadcrumbs">
          <span>메뉴 관리</span>
          <span class="color-black">사용자 메뉴 관리</span>
        </div>
      </div>

      <div class="list-top mt50">
        <h3></h3>
        <div class="control-group">
          <div class="btn-group">
            <button @click="addMenu" type="button" class="navy-button add-button">등록</button>
          </div>
        </div>
      </div>

      <div class="flex-box evenly mt30">
        <div class="white-panel">
          <div class="tree-menu">
            <ul class="tabletki-tree">
              <TreeNode
                  v-for="node in treeData"
                  :key="node.id"
                  :node="node"
                  @select="handleNodeSelect"
              />
            </ul>
          </div>
        </div>

        <div class="white-panel">
          <div class="hgroup">
            <h3 class="title">분류 등록/수정</h3>
          </div>
          <div class="table-wrap mt20">
            <table class="table-type2">
              <colgroup>
                <col style="width: 35%;">
                <col style="width: 65%;">
              </colgroup>
              <tbody v-if="selectedNode">
              <tr>
                <th>상위 메뉴ID</th>
                <td><input v-model="selectedNode.parentId" type="text" disabled></td>
              </tr>
              <tr>
                <th>메뉴 분류<span class="text-red"> *</span></th>
                <td>
                  <select id="articleType" v-model="selectedNode.type" :disabled="mode === 'update'">
                    <option value="PUBLIC">사용자 메뉴</option>
                    <option value="ADMIN">관리자 메뉴</option>
                  </select>
                </td>
              </tr>
              <tr>
                <th>메뉴 명<span class="text-red"> *</span></th>
                <td><input v-model="selectedNode.name" type="text"></td>
              </tr>
              <tr>
                <th>메뉴 순서<span class="text-red"> *</span></th>
                <td><input v-model="selectedNode.sort" type="number"></td>
              </tr>
              <tr>
                <th>메뉴 설명</th>
                <td><input v-model="selectedNode.description" type="text"></td>
              </tr>
              <tr>
                <th>URL<span class="text-red"> *</span></th>
                <td><input v-model="selectedNode.url" type="text"></td>
              </tr>
              <tr>
                <th>사용 여부<span class="text-red"> *</span></th>
                <td>
                  <div class="radio-group">
                    <span class="radio-type1">
                      <input type="radio" name="use_flag" id="folder_use_flag_y" v-model="selectedNode.isUse" :value="true">
                      <label for="folder_use_flag_y">사용함</label>
                    </span>
                    <span class="radio-type1">
                      <input type="radio" name="use_flag" id="folder_use_flag_n" v-model="selectedNode.isUse" :value="false">
                      <label for="folder_use_flag_n">사용안함</label>
                    </span>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
            <div class="button-panel mt30">
              <div class="left">
              </div>
              <div v-if="selectedNode" class="right">
                <button v-if="mode === 'update'" @click="updateMenu" type="button" class="button navy-button">수정하기</button>
                <button v-if="mode === 'insert'" @click="saveMenu" type="button" class="button navy-button">등록하기</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onMounted, ref} from 'vue';
import {apiClient} from "@/services/auth-header";
import TreeNode from "@/components/TreeNode.vue";
import {useRoute} from "vue-router";

const route = useRoute();
const type = ref(route.params.type);
const mode = ref("update");
const treeData = ref(null)
const selectedNode = ref(null);
const defaultNode = ref({
  parentId: "",
  type: type,
  name: "",
  sort: 0,
  url: "",
  description: "",
  isUse: true,
});

// TreeNode 컴포넌트 정의
const fetchTreeData = async () => {
  await apiClient.post('/menu/list', {
    name: type.value
  })
      .then(response => {
        treeData.value = response.data.sort((a, b) => a.sort - b.sort);
        treeData.value.forEach(data => {
          data.children = data.children.sort((a, b) => a.sort - b.sort);
        })
        console.log(treeData.value)
      })
      .catch(error => {
        console.error('Error:', error);
      });
};

const addMenu = async () => {
  defaultNode.value.parentId = selectedNode.value ? selectedNode.value.id : "";
  selectedNode.value = defaultNode.value;
  mode.value = "insert";
};

const updateMenu = async () => {
  if(!validationMenu()){
    return;
  }
  if(confirm("수정 하시겠습니까?")){
    await apiClient.post('/menu/update', selectedNode.value)
        .then(response => {
          if(response.status === 200){
            alert("수정 되었습니다.");
            fetchTreeData();
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
};

const saveMenu = async () => {
  if(!validationMenu()){
    return;
  }
  if(confirm("등록 하시겠습니까?")){
    await apiClient.post('/menu/insert', selectedNode.value)
        .then(response => {
          if(response.status === 200){
            alert("등록 되었습니다.");
            fetchTreeData();
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
  }
};

const validationMenu = () => {
  if(!selectedNode.value.name){
    alert("메뉴명을 입력 하세요.");
    return false;
  }
  if(!selectedNode.value.sort){
    alert("메뉴 순서를 입력 하세요.");
    return false;
  }
  if(!selectedNode.value.url){
    alert("URL을 입력 하세요.");
    return false;
  }
  if(!selectedNode.value.isUse){
    alert("사용 여부를 선택 하세요.");
    return false;
  }
  return true
};

const handleNodeSelect = (node) => {
  selectedNode.value = node;
  mode.value = "update";
};

onMounted(() => {
  console.log(type.value)
  fetchTreeData();
});
</script>
