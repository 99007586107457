<template>
  <li class="tabletki-tree_li">
    <span @click="onNodeClick" class="tabletki-tree_item">
      <i v-if="node.children && node.children.length > 0 && !isOpen" class="fa fa-plus-circle tabletki-tree_icon"></i>
      <i v-if="isOpen" class="fa tabletki-tree_icon fa-minus-circle"></i>
      {{ node.name }}
    </span>
    <ul v-if="isOpen" class="tabletki-tree_child-list">
      <TreeNode
          v-for="child in node.children"
          :key="child.id"
          :node="child"
          @select="$emit('select', $event)"/>
    </ul>
  </li>
</template>

<script setup>
import {computed, defineEmits, defineProps, onMounted, ref} from 'vue';

const props = defineProps({
  node: {
    type: Object,
    required: true
  }
});
const emit = defineEmits(['select']);
const isOpen = ref(false);
const hasChildren = computed(() => {
  return props.node.children && props.node.children.length > 0;
});

const onNodeClick = () => {
  emit('select', props.node);
  if (hasChildren.value) {
    isOpen.value = !isOpen.value;
  }
};

</script>
<style scoped>
.tabletki-tree_item {
  cursor: pointer;
}

</style>
